import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import throwLoaderError from "../../../lib/throwLoaderError";

export default function adminSettingsLoader() {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/admin/config`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      if(err) throwLoaderError(500, err)
      else return data
    })
}