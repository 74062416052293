//External
import styled, { css } from "styled-components";

//Functions & Vars


//Components

export default function Grid(props) {

  const {
    columns,
    rows,
    gap,
    maxContent,
    verticalAlign,
    children,
    style,
    revert
  } = props;

  let templateColumns = "none"
  let templateRows = "none"
  let gridGap = "none"
  if(columns) templateColumns = columns
  if(rows) templateRows = rows
  if(gap) gridGap = gap
  return (
    <StyledGrid style={style} columns={templateColumns} rows={templateRows} gap={gridGap} maxContent={maxContent} verticalAlign={verticalAlign} revert={revert}>
      { children }
    </StyledGrid>
  );
}

const StyledGrid = styled.div `
  display: grid;
  grid-template-columns: ${props => props.columns};
  grid-template-rows: ${props => props.rows};
  grid-gap: ${props => props.gap};
  ${props => {
    if(props.maxContent) return css `
      height: max-content;
    `
    if(props.verticalAlign) return css `
      align-items: ${props.verticalAlign};
    `
  }}
`