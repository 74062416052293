import SubPage from "../../../components/ui/SubPage";
import Group from "../../../components/ui/Group";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import {useState} from "react";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import SubmitButton from "../../../components/form/SubmitButton";
import Form from "../../../components/form/Form";
import ControlledSelect from "../../../components/form/controlled/Select";
import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import throwPageMessage from "../../../lib/throwPageMessage";
import styled from "styled-components";

export default function DNSResolver() {

  const [message, setMessage] = useState(<></>)

  const [searchTerm, setSearchTerm] = useState("")
  const [recordType, setRecordType] = useState("")
  const [dnsRecords, setDnsRecords] = useState([])

  function onResolveDNS() {
    if(!searchTerm || !searchTerm.includes(".")) return throwPageMessage(setMessage, "error", "Es wurde keine Domain angegeben, oder die Eingabe ist fehlerhaft.")
    if(!recordType) return throwPageMessage(setMessage, "error", "Es wurde kein Typ gewählt, der geprüft werden soll.")
    axios
      .get(`${process.env.REACT_APP_API_URL}/tools/getDNS/${searchTerm}/${recordType}`)
      .then(res => {
        const { err, data } = getDataFromAPIResponse(res)
        if(err) return throwPageMessage(setMessage, "error", err)
        else {
          throwPageMessage(setMessage, "success", "Domaindaten erfolgreich abgerufen.")
          createDNSTiles(data)
        }
      })
  }

  function createDNSTiles(data) {
    let output = []
    if(data.soa) {
      output.push(
        <Group name={"SOA"}>
          <StyledDNSType columns={"max-content 1fr"}>
            <span>Nameserver:</span><span>{data.soa.nsname}</span>
            <span>Hostmaster:</span><span>{data.soa.hostmaster}</span>
            <span>Serial:</span><span>{data.soa.serial}</span>
            <span>Refresh:</span><span>{data.soa.refresh}</span>
            <span>Retry:</span><span>{data.soa.retry}</span>
            <span>Expire:</span><span>{data.soa.expire}</span>
            <span>Min. TTL:</span><span>{data.soa.minttl}</span>
          </StyledDNSType>
        </Group>
      )
    }
    if(data.a && data.a.length) {
      output.push(
        <Group name={"A / IPv4"}>
          <StyledDNSType columns={"max-content 1fr max-content 1fr"}>
            {
              data.a.map(item => {
                return (
                  <>
                    <span>A:</span>
                    <span>{item}</span>
                  </>
                )
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    if(data.aaaa && data.aaaa.length) {
      output.push(
        <Group name={"AAAA / IPv6"}>
          <StyledDNSType columns={"max-content 1fr max-content 1fr"}>
            {
              data.aaaa.map(item => {
                return (
                  <>
                    <span>AAAA:</span>
                    <span>{item}</span>
                  </>
                )
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    if(data.cname && data.cname.length) {
      output.push(
        <Group name={"CNAME"}>
          <StyledDNSType columns={"max-content 1fr max-content 1fr"}>
            {
              data.cname.map(item => {
                return (
                  <>
                    <span>CNAME:</span>
                    <span>{item}</span>
                  </>
                )
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    if(data.mx && data.mx.length) {
      output.push(
        <Group name={"MX"}>
          <StyledDNSType columns={"max-content 1fr max-content 1fr"}>
            {
              data.mx.map(item => {
                return (
                  <>
                    <span>Hostname:</span>
                    <span>{item.exchange}</span>
                    <span>Prio:</span>
                    <span>{item.priority}</span>
                  </>
                )
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    if(data.ns && data.ns.length) {
      output.push(
        <Group name={"NS"}>
          <StyledDNSType columns={"max-content 1fr"}>
            {
              data.ns.map(item => {
                return (
                  <>
                    <span>Hostname:</span>
                    <span>{item}</span>
                  </>
                )
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    if(data.txt && data.txt.length) {
      output.push(
        <Group name={"TXT"}>
          <StyledDNSType columns={"max-content 1fr"}>
            {
              data.txt.map(item => {
                return item.map(txt => {
                  return <><span>TXT:</span><span>{txt}</span></>
                })
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    if(data.caa && data.caa.length) {
      output.push(
        <Group name={"CAA"}>
          <StyledDNSType columns={"max-content 1fr"}>
            {
              data.caa.map(item => {
                if(item.iodef) return <><span>Iodef:</span><span><a href={item.iodef}>{item.iodef.split(":")[1]}</a></span></>
                else if(item.contactemail) return <><span>ContactEmail:</span><span><a href={'mailto:'+item.contactemail}>{item.contactemail}</a></span></>
                else return <><span>Issue:</span><span>{item.issue}</span></>
              })
            }
          </StyledDNSType>
        </Group>
      )
    }
    return setDnsRecords(output)
  }

  return (
    <SubPage>
      { message }
      <Grid columns={"1fr"} gap={defaultStyles.element.padding.default}>
        <Group name={"Suche"} icon={null}>
          <Form onSubmit={onResolveDNS}>
            <Grid columns={"4fr 1fr 1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"(Sub-)Domain"} value={searchTerm} onValueChange={setSearchTerm} />
              <ControlledSelect name={"Typ"} value={recordType} onValueChange={setRecordType}>
                <option value={"SOA"}>SOA</option>
                <option value={"A"}>A</option>
                <option value={"AAAA"}>AAAA</option>
                <option value={"CNAME"}>CNAME</option>
                <option value={"MX"}>MX</option>
                <option value={"NS"}>NS</option>
                <option value={"TXT"}>TXT</option>
                <option value={"CAA"}>CAA</option>
              </ControlledSelect>
              <SubmitButton style={{alignSelf: "end"}}>Auflösen</SubmitButton>
            </Grid>
          </Form>
        </Group>
        <Grid columns={"1fr 1fr 1fr 1fr"} gap={defaultStyles.element.padding.default}>
          {
            dnsRecords.map(item => {
              return item
            })
          }
        </Grid>
      </Grid>
    </SubPage>
  )
}

const StyledDNSType = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  grid-gap: ${defaultStyles.element.padding.small};
  >span:nth-of-type(2n-1) {
    font-weight: 600;
  }
`;