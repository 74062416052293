import { Editor } from "@tinymce/tinymce-react";

export default function TinyMCE(props) {

  function innerOnChange(e) {
    props.onValueChange(e)
  }

  return <Editor
    onEditorChange={innerOnChange}
    value={props.value}
    init={{
      menubar: true,
      plugins: ["link", "table", "image", "code", "preview"],
      browser_spellcheck: true,
    }}
  />
}