import styled from "styled-components";
import ControlledTextInput from "../form/controlled/TextInput";
import defaultStyles from "../../styles/defaultStyles";
import {useRef, useState} from "react";
import DeleteActionIcon from "./action_icons/DeleteActionIcon";

export default function SelectItemsBox(params) {

  const {
    name,
    data,
    onChange
  } = params;

  const [searchTerm, setSearchTerm] = useState("")

  const searchResultsRef = useRef()

  function onSearchFocus() {
    console.log("onFocus")
    searchResultsRef.current.style.display = "block"
  }

  function onSearchBlur() {
    console.log("onBlur")
    setTimeout(() => {
      searchResultsRef.current.style.display = "none"
    }, 100)
  }

  function onItemAdd(id) {
    let newItemsArray = [...data];
    const index = newItemsArray.findIndex((item) => item.id === id)
    newItemsArray[index].selected = true;
    onChange(newItemsArray)
  }

  function onItemRemove(id) {
    let newItemsArray = [...data];
    const index = newItemsArray.findIndex((item) => item.id === id)
    newItemsArray[index].selected = false;
    onChange(newItemsArray)
  }

  return (
    <StyledSelectItemBox>
      <label>{name}</label>
      <ControlledTextInput placeholder={"Suche"} value={searchTerm} onValueChange={setSearchTerm} onFocus={onSearchFocus} onBlur={onSearchBlur} />
      <div className={"searchResults"} ref={searchResultsRef}>
        {
          data.filter(item => item.selected === false && item.name.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => {
            if(index > 9) return null;
            return <div key={index} onClick={() => onItemAdd(item.id)}>{item.name}</div>
          })
        }
      </div>
      <div className={"selectedItems"}>
        {
          data.filter(item => item.selected === true).map((item, index) => {
            return <div key={index} className={"item"}><span>{item.name}</span><DeleteActionIcon color={"#fff"} size={"1.35rem"} onClick={() => onItemRemove(item.id)} /></div>
          })
        }
      </div>
    </StyledSelectItemBox>
  )
}

const StyledSelectItemBox = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${defaultStyles.element.padding.small};
  width: 100%;
  border: ${defaultStyles.element.border.default};
  border-radius: ${defaultStyles.element.border.radius.small};
  padding: ${defaultStyles.element.padding.small};
  div.searchResults {
    display: none;
    position: absolute;
    background-color: #fff;
    top: 4.5rem;
    margin: 0 ${defaultStyles.element.padding.small};
    width: calc(100% - 2 * ${defaultStyles.element.padding.small});
    border: ${defaultStyles.element.border.default};
    border-radius: ${defaultStyles.element.border.radius.small};
    > div {
      padding: ${defaultStyles.element.padding.small};
      cursor: pointer;
    }
  }
  div.selectedItems {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${defaultStyles.element.padding.small};
  }
  div.item {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: ${defaultStyles.element.padding.small};
    background-color: ${defaultStyles.color.theme.default};
    color: #fff;
    padding: ${defaultStyles.element.padding.small};
    border-radius: ${defaultStyles.element.border.radius.small};
  }
`;