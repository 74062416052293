import axios from "axios";
import getDataFromAPIResponse from "../../../../lib/getDataFromAPIResponse";
import throwLoaderError from "../../../../lib/throwLoaderError";

export default function documentVersionCheckLoader({ params }) {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/public/docversion/${params.documentId}/${params.version}`)
    .then(res => {
      const { data, err } = getDataFromAPIResponse(res)
      if(err) return throwLoaderError(500, err)
      else return {
        currentVersion: params.version,
        fileVersion: data
      }
    })
}