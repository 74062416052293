import styled from "styled-components";

import StyledInput from "../../../styles/StyledInput";
import generateUniqueElementId from "../../../lib/generateUniqueElementId";

export default function TextArea(props) {
  const {
    name,
    value,
    disabled,
    maxHeight
  } = props;

  const elementId = generateUniqueElementId();

  function innerOnValueChange(e) {
    e.preventDefault()
    props.onValueChange(e.target.value)
  }

  return (
    <StyledInput>
      <label
        htmlFor={elementId}
      >{ name }</label>
      <textarea
        id={elementId}
        value={value}
        onChange={innerOnValueChange}
        placeholder={name}
        disabled={disabled}
        style={{
          minHeight: maxHeight ? "67vh" : "10rem"
        }}
      />
    </StyledInput>
  )
}

const StyledTextArea = styled.textarea `
  
`