import {useLoaderData} from "react-router-dom";

export default function DocumentVersionCheck() {
  const {
    currentVersion,
    fileVersion
  } = useLoaderData()
  if(parseInt(fileVersion) > parseInt(currentVersion)) {
    return (
      <>
        <h2>Versionsprüfung</h2>
        <p>Eine neuere Version dieses Dokuments ist Verfügbar!</p>
        <p>Ihre Version: {currentVersion} / Verfügbare Version: {fileVersion}</p>
      </>
    )
  }
  else {
    return (
      <>
        <h2>Versionsprüfung</h2>
        <p>Sie besitzen die aktuellste Version (v{fileVersion})!</p>
      </>
    )
  }
}