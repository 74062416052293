//External


//Functions & Vars


//Components
import SubPage from "../../components/ui/SubPage";
import ActionBar from "../../components/ui/action_bar/ActionBar";
import ActionButton from "../../components/ui/action_bar/ActionButton";
import Grid from "../../components/helper/Grid";
import defaultStyles from "../../styles/defaultStyles";
import Group from "../../components/ui/Group";
import ControlledCheckbox from "../../components/form/controlled/Checkbox";
import {useState} from "react";
import {useLoaderData} from "react-router-dom";
import axios from "axios";
import getDataFromAPIResponse from "../../lib/getDataFromAPIResponse";
import throwPageMessage from "../../lib/throwPageMessage";
import ControlledTextInput from "../../components/form/controlled/TextInput";

export default function AdminSettings() {

  const [message, setMessage] = useState(<></>)

  const [settings, setSettings] = useState(useLoaderData())

  function updateConfig(index, value) {
    console.log(value)
    const newSettings = [...settings];
    newSettings[index].value = value.toString()
    console.log(newSettings)
    setSettings([...newSettings]);
  }

  function onConfigSave() {
    const configToSubmit = settings.map(item => {
      return {
        _id: item._id,
        value: item.value
      }
    })
    axios
      .patch(`${process.env.REACT_APP_API_URL}/admin/config`, configToSubmit)
      .then(res => {
        const { err } = getDataFromAPIResponse(res)
        if(err) return throwPageMessage(setMessage, "error", err)
        else return throwPageMessage(setMessage, "success", "Die Konfiguration wurde erfolgreich gespeichert!")
      })
  }

  return (
    <SubPage name={"Einstellungen"}>
      <ActionBar>
        <ActionButton icon={"save"} onClick={onConfigSave} />
      </ActionBar>
      { message }
      <Grid columns={"1fr 1fr 1fr"} gap={defaultStyles.element.padding.default}>
        <Group name={"Modul: Tools"}>
          <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
            {
              settings.filter(item => item.module === "tools").map((item, index) => {
                if(item.type === "Boolean") {
                  let innerValue;
                  innerValue = item.value.toLowerCase() === "true";
                  return (
                    <ControlledCheckbox
                      key={index}
                      value={innerValue}
                      onValueChange={newValue => updateConfig(index, newValue)}
                    >{item.name_ext}</ControlledCheckbox>
                  )
                }
                else {
                  return (
                    <ControlledTextInput name={item.name_ext} value={item.value} onValueChange={newValue => updateConfig(index, newValue)} />
                  )
                }
              })
            }
          </Grid>
        </Group>
      </Grid>
    </SubPage>
  )
}