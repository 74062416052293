export default `<p>
  Die %_KOMMUNE_NAME_% bemüht sich, ihre Webseiten und mobilen Anwendungen barrierefrei zugänglich zu machen. Die Erklärung zur digitalen Barrierefreiheit wird im Niedersächsischen Behindertengleichstellungsgesetz (NBGG) verlangt.
</p>
<p>
  Diese Erklärung zur Barrierefreiheit gilt für die Webseite <a href="https://%_KOMMUNE_URL_%"  title="%_KOMMUNE_URL_% | Startseite" target="_blank" rel="noopener">%_KOMMUNE_URL_%</a>. <br /><br /><br />
  <strong>Stand der Vereinbarkeit mit den Anforderungen</strong> <br />
  Dieser Webauftritt ist überwiegend, aber noch nicht vollumfänglich barrierefrei. Die Anforderungen der BITV 2.0 werden weitgehend erfüllt.<br /><br />
</p>
<p><strong>Momentan werden folgende Funktionen unterstützt</strong></p>
<ul>
  <li>Ausreichende Kontraste</li>
  <li>Schrift/Darstellung vergrößern: Die Vergrößerung der Seitendarstellung mit den gängigen Browsern (zum Beispiel Firefox, Google Chrome, Microsoft Edge, Opera und Apple Safari) erfolgt bei Windows mit „STRG“ und „+“ und bei Apple mit „cmd“ und „+“.</li>
  <li>Schrift/Darstellung verkleinern: Die Verkleinerung der Seitendarstellung mit den gängigen Browsern (zum Beispiel Firefox, Google Chrome, Microsoft Edge, Opera und Apple Safari) erfolgt bei Windows mit „STRG“ und „-“ und bei Apple mit „cmd“ und „-“.</li>
  <li>Die Inhalte der Webseite passen sich der Bildschirmgröße des genutzten Geräts automatisch an (responsive Webdesign).</li>
  <li>Hinterlegung von Alternativtexten für Bilder. Beim Gleiten der Maus über ein Bild wird der Bildinhalt erläutert. Diese Angaben werden von einer Vorlese-Software berücksichtigt.</li>
  <li>Logischer Aufbau und Gliederung der Inhalte.<br /><br /></li>
</ul>
<p>
  <strong>Nicht barrierefreie Inhalte</strong><br />
  Die nachstehend aufgeführten Inhalte sind aus folgenden Gründen nicht barrierefrei:
</p>
<ul>
  <li>In den Webauftritt eingebundene Dokumente (.pdf, .doc, etc.)<br /><em>Begründung:</em><br /><em>Die große Anzahl bereitgestellter Dokumente, die teilweise bereits älteren Datum sind, hat bisher eine Übertragung in ein barrierefreies Format nicht ermöglicht. Diese Dokumente werden sukzessive angepasst.</em><br /><em>Dokumente, die von Dritten (z.B. andere Organisationen, Ministerien, u.a.) bereitgestellt werden, liegen nicht barrierefrei vor.<br /><br /></em></li>
  <li>In den Webauftritt eingebundene Video- und und Audio-Elemente<br /><em>Begründung:</em><br /><em>Die Bereitstellung von Video- und Audiodeskriptionen konnte bislang nicht realisiert werden.<br /><br /></em></li>
  <li>Kartografie innerhalb der Webseite<br /><em>Begründung:</em><br /><em>Eine kartografische Darstellung ist eine hilfreiche und sehr nützliche Ergänzung von Informationen. Kartografie ist dem Wesen nach eine bildliche Darstellung, die somit den Anforderungen der BITV nicht entsprechen kann.<br /><br /></em></li>
  <li>Redaktionelle Inhalte (teilweise)<br /><em>Begründung:</em><br /><em>Auf Grund der komplexen Inhalte, die zumindest teilweise in dieser Webseite veröffentlicht werden, ist eine einfache Sprachgestaltung (beispielsweise der Verzicht auf Fachbegriffe, ggf. auch fremdsprachige) nicht immer möglich.</em><br /><em>Zur Verdeutlichung von Zusammenhängen und zur besseren Veranschaulichung werden bildliche Darstellungen (z.B. Infografiken) oder tabellarische Auflistungen verwendet. Diese sind nicht barrierefrei darstellbar.<br /><br /></em></li>
  <li>
    <p>Hauptnavigation und Elemente der Startseite (unter bestimmten Voraussetzungen)<br /><em>Begründung:</em><br /><em>Die Anforderung, dass sämtliche Textinhalte sich in Bezug auf die Zeilen-, Absatz-, Wort- und Buchstaben-Abstände auf bestimmte Werte vergrößern lassen müssen, ist bei den vier Hauptnavigationspunkten sowie beim Meldungs- und Veranstaltungsblock auf der Startseite aufgrund der Vorgaben des Layouts nicht zu 100 % technisch umsetzbar. Diese Elemente wären bei entsprechender Vergrößerung der Abstände nicht mehr vollständig bzw. qualitativ lesbar. </em></p>
  </li>
</ul>
<p>
  <br /><strong>Ihre Hinweise und Anregungen</strong><br />
  Unser Ziel ist es, Artikel so zu schreiben, dass sie auch von Besuchern verstanden werden, die mit der jeweiligen Thematik nicht oder nur oberflächlich vertraut sind. Gerne nehmen wir Ihre Anregungen auf, wenn Ihrer Ansicht nach ein Beitrag die zu vermittelnden Inhalte unnötig kompliziert darstellt.<br />
  Nutzen Sie dazu gerne unser entsprechendes Formular, das Sie unter folgendem Link aufrufen können:
  <a href="https://%_KOMMUNE_URL_%/barrierefreiheit/barriere_melden.html"  title="Barriere melden (%_KOMMUNE_URL_%/barrierefreiheit/barriere_melden.html)" target="_blank">Klicken Sie hier, um eine Barriere zu melden.</a><br /><br />
</p>
<p>oder wenden Sie sich an:</p>
<p>%_KOMMUNE_NAME_%<br />%_KOMMUNE_AP_%<br />%_KOMMUNE_ADDRESS_%<br />%_KOMMUNE_PLZ_ORT_%<br />Telefon: %_KOMMUNE_TEL_%<br />Telefax: %_KOMMUNE_FAX_%<br />E-Mail: <a  href="mailto:%_KOMMUNE_MAIL_%">%_KOMMUNE_MAIL_%</a><br />Internet: <a href="%_KOMMUNE_URL_%"  title="%_KOMMUNE_NAME_% | Startseite" target="_blank" rel="noopener">%_KOMMUNE_URL_%</a></p>
<p>
  <strong>Schlichtungsstelle</strong><br />
  Das Land Niedersachsen richtet laut § 9 d, Abs. 1 NBGG eine Schlichtungsstelle bei der Landesbeauftragten für Menschen mit Behinderungen ein. Kontaktdaten:<br />
  Schlichtungsstelle nach § 9d NBGG im Büro der Landesbeauftragten für Menschen mit Behinderungen<br /><br />
  
  Hannah-Arendt-Platz 2 <br />
  30159 Hannover<br />
  Tel.: 0511/120-4010<br />
  Fax: 0511/120-99 4010<br />
  E-Mail: <a class="link_katalog email nolis-link-intern nolis-link-mailto" href="mailto:schlichtungsstelle@ms.niedersachsen.de" title="E-Mail an schlichtungsstelle@ms.niedersachsen.de schreiben">schlichtungsstelle@ms.niedersachsen.de</a><br />
  <a class="link_katalog email nolis-link-intern nolis-link-mailto" href="mailto:Landesbeauftragte@ms.niedersachsen.de" title="E-Mail an Landesbeauftragte@ms.niedersachsen.de schreiben">Landesbeauftragte@ms.niedersachsen.de</a><br /><br />
</p>
<p>
  <strong>Erstellung dieser Erklärung zur Barrierefreiheit</strong><br />
  Diese Erklärung wurde am %_KOMMUNE_GENDATE_% erstellt.<br />Die Erklärung wurde zuletzt am %_KOMMUNE_GENDATE_% überprüft.
</p>`;