import { createBrowserRouter } from "react-router-dom";

import Layout from "../components/layout/Layout";
import Login from "../pages/login/Login";
import MyProfile from "../pages/MyProfile";
import rootLoader from "./loader/rootLoader";
import AdminLayout from "../pages/admin/AdminLayout";
import UserManagement from "../pages/admin/UserManagement";
import userManagementLoader from "./loader/admin/userManagementLoader";
import UserDetails from "../pages/admin/UserDetails";
import userDetailsLoader from "./loader/admin/userDetailsLoader";
import AdminSettings from "../pages/admin/AdminSettings";
import UserAdd from "../pages/admin/UserAdd";
import Dashboard from "../pages/Dashboard";
import ErrorElement from "../pages/ErrorElement";
import NotFound from "../pages/NotFound";
import ProjectLayout from "../pages/products/projects/ProjectLayout";
import MyProjects from "../pages/products/projects/MyProjects";
import ProjectDetails from "../pages/products/projects/ProjectDetails";
import ToolsLayout from "../pages/products/tools/ToolsLayout";
import NOLISPdf from "../pages/products/tools/NOLISPdf";
import NOLISPdfDashboard from "../pages/products/tools/NOLISPdfDashboard";
import NOLISPdfDashboardLoader from "./loader/products/tools/NOLISPdfDashboardLoader";
import NOLISPdfDetailsLoader from "./loader/products/tools/NOLISPdfDetailsLoader";
import DocumentVersionCheck from "../pages/public/DocumentVersionCheck";
import documentVersionCheckLoader from "./loader/products/tools/documentVersionCheckLoader";
import PublicLayout from "../pages/public/PublicLayout";
import DNSResolver from "../pages/products/tools/DNSResolver";
import adminSettingsLoader from "./loader/admin/adminSettingsLoader";
import SendMail from "../pages/products/tools/SendMail";
import BFGen from "../pages/products/tools/BFGen";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/public",
    element: <PublicLayout />,
    children: [
      {
        path: "docversion/:documentId/:version",
        element: <DocumentVersionCheck />,
        loader: documentVersionCheckLoader
      }
    ]
  },
  {
    path: "/",
    element: <Layout />,
    id: "root",
    loader: rootLoader,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "meinprofil",
        element: <MyProfile />,
        errorElement: <ErrorElement />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement: <ErrorElement />,
      },
      //============Projects
      {
        path: "projekte",
        element: <ProjectLayout />,
        children: [
          {
            path: "meine-projekte",
            element: <MyProjects />,
            errorElement: <ErrorElement />,
          },
          {
            path: ":projectId",
            element: <ProjectDetails />,
            errorElement: <ErrorElement />,
          }
        ]
      },
      //====================
      //===============TOOLS
      {
        path: "tools",
        element: <ToolsLayout />,
        children: [
          {
            path: "dokumente",
            element: <NOLISPdfDashboard />,
            loader: NOLISPdfDashboardLoader,
            errorElement: <ErrorElement />,
          },
          {
            path: "dokumente/:documentId",
            element: <NOLISPdf />,
            loader: NOLISPdfDetailsLoader,
            errorElement: <ErrorElement />,
          },
          {
            path: "mail",
            element: <SendMail />,
            loader: NOLISPdfDashboardLoader,
            errorElement: <ErrorElement />
          },
          {
            path: "dns-resolver",
            element: <DNSResolver />,
            errorElement: <ErrorElement />
          },
          {
            path: "bfgen",
            element: <BFGen />,
            errorElement: <ErrorElement />
          }
        ]
      },
      //====================
      {
        path: "admin",
        element: <AdminLayout />,
        children: [
          {
            path: "benutzerverwaltung",
            element: <UserManagement />,
            loader: userManagementLoader,
            errorElement: <ErrorElement />,
          },
          {
            path: "benutzerverwaltung/erstellen",
            element: <UserAdd />,
            errorElement: <ErrorElement />,
          },
          {
            path: "benutzerverwaltung/:userId",
            element: <UserDetails />,
            loader: userDetailsLoader,
            errorElement: <ErrorElement />,
          },
          {
            path: "einstellungen",
            element: <AdminSettings />,
            loader: adminSettingsLoader,
            errorElement: <ErrorElement />,
          }
        ]
      },
      {
        path: "*",
        element: <NotFound />
      }
    ]
  }
])

export default router