import axios from "axios";
import getDataFromAPIResponse from "../../../../lib/getDataFromAPIResponse";
import throwLoaderError from "../../../../lib/throwLoaderError";

export default async function NOLISPdfDetailsLoader({params}) {
  if(params.documentId === "neu") {
    const categories = await axios
      .get(`${process.env.REACT_APP_API_URL}/categories/documents`)
      .then(res => {
        const { err, data } = getDataFromAPIResponse(res)
        if(err) return throwLoaderError(500, err)
        else return data
      })
    return {
      document: {
        _id: null,
        name: "",
        content: "",
        mimeType: "",
        fileName: "",
        fileExtension: "",
        fileDate: null,
        fileVersion: 1,
        author: null,
        category: null,
        discover: "public"
      },
      categories
    }
  }
  else {
    const documentPromise = axios
      .get(`${process.env.REACT_APP_API_URL}/tools/documents/${params.documentId}`)
      .then(res => {
        const { data, err } = getDataFromAPIResponse(res)
        if(err) return throwLoaderError(500, err)
        else return data
      })
      .catch(err => {
        return throwLoaderError(500, err.message)
      })
    const categoryPromise = axios
      .get(`${process.env.REACT_APP_API_URL}/categories/documents`)
      .then(res => {
        const { err, data } = getDataFromAPIResponse(res)
        if(err) return throwLoaderError(500, err)
        else return data
      })
      .catch(err => {
        return throwLoaderError(500, err.message)
      })

    const [
      document,
      categories
    ] = await Promise.all([
      documentPromise,
      categoryPromise
    ])

    return {
      document,
      categories
    }
  }
}