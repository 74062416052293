import SubPage from "../../../components/ui/SubPage";
import TinyMCE from "../../../components/form/controlled/TinyMCE";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import {useState} from "react";
import axios from "axios";
import Group from "../../../components/ui/Group";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";

import download from "downloadjs"
import throwPageMessage from "../../../lib/throwPageMessage";
import {useLoaderData, useNavigate, useRouteLoaderData} from "react-router-dom";
import updateState from "../../../lib/updateState";
import ControlledDateInput from "../../../components/form/controlled/DateInput";
import ControlledNumericInput from "../../../components/form/controlled/NumericInput";
import ControlledSelect from "../../../components/form/controlled/Select";
import ControlledCheckbox from "../../../components/form/controlled/Checkbox";

export default function NOLISPdf() {

  const documentLoaderData = useLoaderData().document
  const categories = useLoaderData().categories
  const userData = useRouteLoaderData("root")
  const navigate = useNavigate()

  const newEntry = !documentLoaderData._id;

  const [message, setMessage] = useState(<></>)

  const [document, setDocument] = useState(documentLoaderData)

  function onContentSave() {
    if(newEntry) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/tools/documents`, {
          name: document.name,
          html: document.content,
          authorId: userData.id,
          category: document.category,
          discover: document.discover
        })
        .then(res => {
          const { data, err } = getDataFromAPIResponse(res)
          if(err) return throwPageMessage(setMessage, "error", err)
          else {
            updateState(document, setDocument, ["_id"], data, true)
            navigate(`../dokumente/${data}`, { replace: true })
            return throwPageMessage(setMessage, "success", "Die Daten wurden erfolgreich gespeichert!")
          }
        })
    }
    else {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/tools/documents/${document._id}`, {
          name: document.name,
          html: document.content,
          fileDate: document.fileDate,
          fileVersion: document.fileVersion,
          authorId: userData.id,
          category: document.category,
          discover: document.discover
        })
        .then(res => {
          const { err } = getDataFromAPIResponse(res)
          if(err) return throwPageMessage(setMessage, "error", err)
          else return throwPageMessage(setMessage, "success", "Die Daten wurden erfolgreich gespeichert!")

        })
    }
  }

  function onDownloadDocumentFile() {
    throwPageMessage(setMessage, "info", "Dokument wird generiert; dies kann einen Moment dauern.")
    axios
      .get(`${process.env.REACT_APP_API_URL}/tools/documents/download/${document._id}`, { responseType: "blob" })
      .then(res => {
        const file = new Blob(
          [res.data],
          { type: 'application/pdf',
            name: "Test" }
        )
        //const fileUrl = URL.createObjectURL(file)
        let currentDate;
        if(document.fileDate) currentDate = new Date(document.fileDate);
        else currentDate = new Date()
        const dateString = `${currentDate.getFullYear()}_${currentDate.getMonth()+1}_${currentDate.getDate()}`
        download(file, `${dateString}_${document.name}.${document.fileExtension}`, document.mimeType)
        return throwPageMessage(setMessage, "success", "Das Dokument wurde heruntergeladen.")
      })
  }

  function onSendDocumentViaMail() {
    axios
      .post(`${process.env.REACT_APP_API_URL}/tools/documents/sendViaMail`, {})
      .then(res => {
        console.log(res)
      })
  }

  function onArchiveDocument() {
    axios
      .post(`${process.env.REACT_APP_API_URL}/tools/documents/archive/${document._id}`)
      .then(res => {
        const { err } = getDataFromAPIResponse(res)
        if(err) return throwPageMessage(setMessage, "error", err)
        else return throwPageMessage(setMessage, "success", "Dokument wurde erfolgreich arhiviert.")
      })
  }

  function onDeleteDocument() {
    if(window.confirm(`Möchten Sie das Dokument "${document.name}" wirklich löschen?`)) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/tools/documents/${document._id}`)
        .then(res => {
          const { err } = getDataFromAPIResponse(res)
          if(err) return throwPageMessage(setMessage, "error", err)
          else {
            alert("Der Datensatz wurde erfolgreich gelöscht!")
            return navigate("../dokumente", { replace: true })
          }
        })
    }
  }

  return (
    <SubPage>
      <ActionBar buttonss={[
        <ActionButton title={"Dokument speichern"} icon={"save"} onClick={onContentSave} />
      ]} >

        { !newEntry && (
          <>
            <ActionButton title={"Dokument herunterladen"} icon={"download"} onClick={onDownloadDocumentFile} />
            { (parseInt(process.env.REACT_APP_SHOW_HIDDEN_FEATURES) === 1) && <ActionButton icon={"mail_out"} onClick={onSendDocumentViaMail}/>}
            <ActionButton title={"Dokument archivieren"} icon={"archive"} onClick={onArchiveDocument} />
            <ActionButton title={"Dokument löschen"} icon={"delete"} onClick={onDeleteDocument} />
          </>
        ) }
      </ActionBar>
      { message }
      <Group name={`Dokument: ${document.name}`}>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
          <Grid columns={"3fr 1fr 1fr 1fr 1fr 1fr"} gap={defaultStyles.element.padding.small}>
            <ControlledTextInput name={"Name"} value={document.name} onValueChange={newValue => updateState(document, setDocument, ["name"], newValue, true)} />
            <ControlledDateInput name={"Dokumentendatum"} value={document.fileDate} onValueChange={newValue => updateState(document, setDocument, ["fileDate"], newValue, true)} />
            <ControlledSelect name={"Kategorie"} value={document.category} onValueChange={newValue => updateState(document, setDocument, ["category"], newValue, true)}>
              {
                categories.map((category, index) => {
                  return <option key={index} value={category._id}>{category.name}</option>
                })
              }
            </ControlledSelect>
            <ControlledNumericInput name={"Dokumentenversion"} value={document.fileVersion} onValueChange={newValue => updateState(document, setDocument, ["fileVersion"], newValue, true)} min={1} />
            <ControlledSelect name={"Auffindbarkeit"} value={document.discover} onValueChange={newValue => updateState(document, setDocument, ["discover"], newValue, true)}>
              <option value={"public"}>Öffentlich</option>
              <option value={"private"}>Privat</option>
            </ControlledSelect>
            <ControlledTextInput name={"Autor"} value={!document.author ? "Unbekannt" : `${document.author.prename} ${document.author.surname}`} disabled />
          </Grid>
          <TinyMCE
            value={document.content}
            onValueChange={newValue => updateState(document, setDocument, ["content"], newValue, true)}
          />
        </Grid>
      </Group>
    </SubPage>
  )
}