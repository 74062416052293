import styled from "styled-components";
import defaultStyles from "../../../styles/defaultStyles";

export default function Legend(props) {

  const {
    legend
  } = props;

  return (
    <StyledLegend>
      {
        legend.map(item => {
          return (
            <div>
              <span style={{
                backgroundColor: item.color
              }}></span>
              <span>{item.title}</span>
            </div>
          )
        })
      }
    </StyledLegend>
  )
}

const StyledLegend = styled.div`
  display: flex;
  gap: ${defaultStyles.element.padding.default};
  align-items: center;
  height: 3rem;
  background-color: #fff;
  border-radius: ${defaultStyles.element.border.radius.default};
  padding: ${defaultStyles.element.padding.small};
  box-shadow: ${defaultStyles.element.boxShadow};
  > div {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: ${defaultStyles.element.padding.small};
    > span:first-of-type {
      display: block;
      width: 1.35rem;
      height: 1.35rem;
      //border: 1px solid black;
      box-shadow: 0 0 .1rem 0 rgba(0,0,0,.25);
      border-radius: ${defaultStyles.element.border.radius.small};
    }
  }
`;