//External


//Functions & Vars


//Components
import Icon from "../../helper/Icon";

export default function TableHeadRow(props) {

  const {
    status,
    columns,
    edit,
    onSortChange
  } = props;

  function innerOnSortChange(value) {
    props.onSortChange(value)
  }

  return (
    <thead>
    <tr>
      {
        status && <th className={"status"}>Status</th>
      }
      {
        columns.map((item, index) => {
          if(onSortChange) {
            return <th key={index} style={{ cursor: "pointer" }} onClick={() => innerOnSortChange(index)}>{ item } <Icon icon={"sort"} size={"1rem"} color={"#fff"} /></th>
          }
          else {
            return <th key={index}>{ item }</th>
          }
        })
      }
      {
        edit && <th className={"edit"}><Icon icon={"edit"} size={"1.5rem"} color={"#fff"} /></th>
      }
    </tr>
    </thead>
  );
}