import axios from "axios";
import getDataFromAPIResponse from "../../../../lib/getDataFromAPIResponse";
import throwLoaderError from "../../../../lib/throwLoaderError";

export default async function NOLISPdfDashboardLoader() {
  const documentsPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/tools/documents`)
    .then(res => {
      const { data, err } = getDataFromAPIResponse(res)
      if(err) return throwLoaderError(500, err)
      else return data
    })
  const usersPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/users`)
    .then(res => {
      const { data, err } = getDataFromAPIResponse(res)
      if(err) return throwLoaderError(500, err)
      else return data
    })
  const categoriesPromise = axios
    .get(`${process.env.REACT_APP_API_URL}/categories/documents`)
    .then(res => {
      const { err, data } = getDataFromAPIResponse(res)
      if(err) return throwLoaderError(500, err)
      else return data
    })

  const [documents, users, categories] = await Promise.all([documentsPromise, usersPromise, categoriesPromise])
  return {
    documents,
    users,
    categories
  }
}