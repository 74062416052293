//External
import {
  Outlet
} from "react-router-dom";

//Functions & Vars


//Components
import PageNavBar from "../../../components/ui/page_navigation/PageNavBar";
import NavTile from "../../../components/ui/page_navigation/NavTile";

export default function ProjectLayout() {
  return (
    <>
      <PageNavBar name={"Projekte"}>
        <NavTile to={"meine-projekte"}>Meine Projekte</NavTile>
        <NavTile to={"einstellungen"}>Einstellungen</NavTile>
      </PageNavBar>
      <Outlet />
    </>
  )
}