import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import {useState} from "react";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import Group from "../../../components/ui/Group";
import TinyMCE from "../../../components/form/controlled/TinyMCE";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import SelectItemsBox from "../../../components/ui/SelectItemsBox";
import {useLoaderData, useRouteLoaderData} from "react-router-dom";
import updateState from "../../../lib/updateState";
import axios from "axios";
import getDataFromAPIResponse from "../../../lib/getDataFromAPIResponse";
import throwPageMessage from "../../../lib/throwPageMessage";

export default function SendMail() {

  const [message, setMessage] = useState(<></>)

  const userData = useRouteLoaderData("root")

  const initialData = useLoaderData().documents.filter(document => !document.archived).map(item => {
    return {
      id: item._id,
      name: item.name,
      selected: false
    }
  })

  const [mailData, setMailData] = useState({
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    text: ""
  })
  const [attachments, setAttachments] = useState(initialData)

  function onMailSend() {
    throwPageMessage(setMessage, "info", "E-Mail wird versendet; dies kann einen Moment dauern.")
    const attachmentDocumentIds = attachments.filter(item => item.selected).map(item => {
      return item.id
    })
    axios
      .post(`${process.env.REACT_APP_API_URL}/tools/mail/send/${userData.id}`, {
        to: mailData.to,
        cc: mailData.cc,
        bcc: mailData.bcc,
        subject: mailData.subject,
        text: mailData.text,
        attachments: attachmentDocumentIds
      })
      .then(res => {
        const { err } = getDataFromAPIResponse(res);
        if(err) return throwPageMessage(setMessage, "error", err)
        else return throwPageMessage(setMessage, "success", "Mail wurde erfolgreich gesendet!")
      })
  }

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"mail_out"} onClick={onMailSend} />
      </ActionBar>
      { message }
      <Grid columns={"1fr 3fr"} gap={defaultStyles.element.padding.default}>
        <Group name={"Einstellungen"} maxContent>
          <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
            <ControlledTextInput name={"An"} value={mailData.to} onValueChange={newValue => updateState(mailData, setMailData, ["to"], newValue, true)} />
            <ControlledTextInput name={"CC"} value={mailData.cc} onValueChange={newValue => updateState(mailData, setMailData, ["cc"], newValue, true)} />
            <ControlledTextInput name={"BCC"} value={mailData.bcc} onValueChange={newValue => updateState(mailData, setMailData, ["bcc"], newValue, true)} />
            <span>Mehrere Adressen mit ; getrennt.</span>
            <SelectItemsBox name={"Anlagen"} data={attachments} onChange={setAttachments} />
          </Grid>
        </Group>
        <Group name={"E-Mail"}>
          <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
            <ControlledTextInput name={"Betreff"} value={mailData.subject} onValueChange={newValue => updateState(mailData, setMailData, ["subject"], newValue, true)} />
            <TinyMCE value={mailData.text} onValueChange={newValue => updateState(mailData, setMailData, ["text"], newValue, true)} />
          </Grid>
        </Group>
      </Grid>
    </SubPage>
  )
}