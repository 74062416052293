//External
import { useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import axios from "axios";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components
import SubPage from "../../components/ui/SubPage";
import Grid from "../../components/helper/Grid";
import Form from "../../components/form/Form";
import Message from "../../components/ui/Message";
import ActionButton from "../../components/ui/action_bar/ActionButton";
import ActionBar from "../../components/ui/action_bar/ActionBar";
import Group from "../../components/ui/Group";
import ControlledTextInput from "../../components/form/controlled/TextInput";
import ControlledPasswordInput from "../../components/form/controlled/PasswordInput";
import ControlledCheckbox from "../../components/form/controlled/Checkbox";
import passwordCheckFailed from "../../lib/checkPassword";
import throwPageMessage from "../../lib/throwPageMessage";
import getDataFromAPIResponse from "../../lib/getDataFromAPIResponse";

export default function UserAdd() {

  const navigate = useNavigate()

  const [userData, setUserData] = useState({
    username: "",
    prename: "",
    surname: "",
    password: "",
    passwordRepeat: "",
    email: "",
    admin: false,
    active: false
  })

  const [message, setMessage] = useState(<></>)

  function updateState(objKey, value) {
    setUserData({
      ...userData,
      [objKey]: value
    })
  }

  function onUserSave() {
    const passwordFailString = passwordCheckFailed(userData.password, userData.passwordRepeat)
    if(passwordFailString)
      return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={passwordFailString} />)
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/users`, {
        username: userData.username,
        prename: userData.prename,
        surname: userData.surname,
        email: userData.email,
        password: userData.password,
        passwordRepeat: userData.passwordRepeat,
        admin: userData.admin,
        active: userData.active,
      })
      .then((res) => {
        const { err } = getDataFromAPIResponse(res)
        if(err) throwPageMessage(setMessage, "error", err)
        else {
          const userId = res.data.data
          alert(`Der Benutzer ${userData.prename} ${userData.surname} (Benutzername: ${userData.username}) wurde erfolgreich erstellt!`)
          return navigate(`/admin/benutzerverwaltung/${userId}`)
        }
      })
  }

  return (
    <SubPage name={`Benutzerverwaltung / Benutzer erstellen`}>
      <ActionBar>
        <ActionButton icon={"save"} title={"Benutzer speichern"} onClick={onUserSave}></ActionButton>
      </ActionBar>
      { message }
      <Grid columns={"1fr 1fr"}>
        <Group name={"Neuen Benutzer erstellen"} icon={"person"}>
          <Form onSubmit={onUserSave}>
            <ControlledTextInput name={"Benutzername"} value={userData.username} onValueChange={(newValue) => updateState("username", newValue)} />
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledTextInput name={"Vorname"} value={userData.prename} onValueChange={(newValue) => updateState("prename", newValue)} />
              <ControlledTextInput name={"Nachname"} value={userData.surname} onValueChange={(newValue) => updateState("surname", newValue)} />
            </Grid>
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledPasswordInput name={"Neues Passwort"} value={userData.password} onValueChange={(newValue) => updateState("password", newValue)} />
              <ControlledPasswordInput name={"Neues Passwort wiederholen"} value={userData.passwordRepeat} onValueChange={(newValue) => updateState("passwordRepeat", newValue)} />
            </Grid>
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledTextInput name={"E-Mail"} value={userData.email} onValueChange={(newValue) => updateState("email", newValue)} />
            </Grid>
            <Grid columns={"1fr 1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledCheckbox value={userData.admin} onValueChange={(newValue) => updateState("admin", newValue)} >Administrator</ControlledCheckbox>
              <ControlledCheckbox value={userData.active} onValueChange={(newValue) => updateState("active", newValue)} >Benutzer aktiv</ControlledCheckbox>
            </Grid>
          </Form>
        </Group>
      </Grid>
    </SubPage>
  )
}