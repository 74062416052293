//External
import {
  useState
} from "react";
import {
  useLoaderData,
  useNavigate
} from "react-router-dom";
import axios from "axios";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";
import passwordCheckFailed from "../../lib/checkPassword";

//Components
import SubPage from "../../components/ui/SubPage";
import Grid from "../../components/helper/Grid";
import Form from "../../components/form/Form";
import Message from "../../components/ui/Message";
import ActionBar from "../../components/ui/action_bar/ActionBar";
import ActionButton from "../../components/ui/action_bar/ActionButton";
import Group from "../../components/ui/Group";
import ControlledTextInput from "../../components/form/controlled/TextInput";
import ControlledPasswordInput from "../../components/form/controlled/PasswordInput";
import ControlledCheckbox from "../../components/form/controlled/Checkbox";
import throwPageMessage from "../../lib/throwPageMessage";
import getDataFromAPIResponse from "../../lib/getDataFromAPIResponse";


export default function UserDetails() {

  const navigate = useNavigate()
  const { user } = useLoaderData()

  const [userData, setUserData] = useState({
    username: user.username,
    prename: user.prename,
    surname: user.surname,
    password: "",
    passwordRepeat: "",
    email: user.email,
    admin: user.admin,
    active: user.active
  })

  const [message, setMessage] = useState(<></>)

  function updateState(objKey, value) {
    setUserData({
      ...userData,
      [objKey]: value
    })
  }

  function onUserSave() {
    const passwordFailMessage = passwordCheckFailed(userData.password, userData.passwordRepeat)
    if(passwordFailMessage)
      return setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={passwordFailMessage} />)
    if(!userData.active) {
      updateState("admin", false)
    }

    axios
      .patch(`${process.env.REACT_APP_API_URL}/admin/users/${user.id}`, {
        username: userData.username,
        prename: userData.prename,
        surname: userData.surname,
        password: userData.password,
        passwordRepeat: userData.passwordRepeat,
        email: userData.email,
        admin: userData.admin,
        active: userData.active
      })
      .then((res) => {
        if(res.data.err) setMessage(<Message type={"error"} title={defaultStyles.messages.save.error} message={res.data.err} />)
        else {
          setMessage(<Message type={"success"} title={defaultStyles.messages.save.success}/>)
          setTimeout(() => {
            setMessage(<></>)
          }, 3000)
        }
      })
  }

  function onUserDelete() {
    if(window.confirm(`Möchten Sie den Benutzer ${user.prename} ${user.surname} (Benutzername: ${user.username}) wirklich löschen?`)) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/admin/users/${user.id}`)
        .then(res => {
          const { err } = getDataFromAPIResponse(res)
          if(err) throwPageMessage(setMessage, "error", err);
          else {
            alert("Der Benutzer wurde erfolgreich gelöscht!")
            return navigate("/admin/benutzerverwaltung")
          }
        })
    }
  }

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"save"} title={"Benutzer speichern"} onClick={onUserSave}></ActionButton>
        <ActionButton icon={"delete"} title={"Löschen"} onClick={onUserDelete}></ActionButton>
      </ActionBar>
      { message }
      <Grid columns={"1fr 1fr"}>
        <Group name={`Benutzer: ${user.prename} ${user.surname} (Benutzername: ${user.username})`} icon={"person"}>
          <Form onSubmit={onUserSave}>
            <ControlledTextInput name={"Benutzername"} value={userData.username} onValueChange={(newValue) => updateState("username", newValue)} />
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledTextInput name={"Vorname"} value={userData.prename} onValueChange={(newValue) => updateState("prename", newValue)} />
              <ControlledTextInput name={"Nachname"} value={userData.surname} onValueChange={(newValue) => updateState("surname", newValue)} />
            </Grid>
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledPasswordInput name={"Neues Passwort"} value={userData.password} onValueChange={(newValue) => updateState("password", newValue)} />
              <ControlledPasswordInput name={"Neues Passwort wiederholen"} value={userData.passwordRepeat} onValueChange={(newValue) => updateState("passwordRepeat", newValue)} />
            </Grid>
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledTextInput name={"E-Mail"} value={userData.email} onValueChange={(newValue) => updateState("email", newValue)} />
            </Grid>
            <Grid columns={"1fr 1fr 1fr"} gap={defaultStyles.element.padding.default}>
              <ControlledCheckbox value={userData.admin} onValueChange={(newValue) => updateState("admin", !!newValue)} >Administrator</ControlledCheckbox>
              <ControlledCheckbox value={userData.active} onValueChange={(newValue) => updateState("active", !!newValue)} >Benutzer aktiv</ControlledCheckbox>
            </Grid>
          </Form>
        </Group>
      </Grid>
    </SubPage>
  )
}