//External
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components
import Icon from "../../helper/Icon";


export default function ActionButton(props) {

  const {
    to,
    title,
    icon,
    count,
    onClick
  } = props;

  function onClickHandler(e) {
    e.preventDefault()
    onClick()
  }

  if(to) {
    return (
      <StyledActionButton delete={false}>
        <Link to={to} title={title}>
          <Icon icon={icon} size={"1.75rem"} color={"#fff"} />
          {
            count > 0 && <span className={"count"}>{ count }</span>
          }
        </Link>
      </StyledActionButton>
    );
  }
  else {
    let deleteButton = false
    if(icon === "delete") deleteButton = true
    return (
      <StyledActionButton delete={deleteButton}>
        <button type={"button"} onClick={onClickHandler} title={title}>
          <Icon icon={icon} size={"1.75rem"} color={"#fff"} />
          {
            count > 0 && <span className={"count"}>{ count }</span>
          }
        </button>
      </StyledActionButton>
    );
  }
}

const StyledActionButton = styled.div `
  position: relative;
  border-radius: 50%;
  > a, button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border: 0;
    border-radius: 50%;
    background-color: ${defaultStyles.color.theme.default};
    cursor: pointer;
    &:hover {
      ${(props) => {
        if(props.delete) return css `
          background-color: ${defaultStyles.color.red.dark};
        `
        else return css `
          background-color: ${defaultStyles.color.theme.hover};
        `
      }}
      box-shadow: ${defaultStyles.element.boxShadow};
    }
    > span.count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-25%) translateX(25%);
      width: 1.5rem;
      height: 1.5rem;
      background-color: ${defaultStyles.color.red.dark};
      border: .1rem solid #fff;
      border-radius: 50%;
      color: #fff;
    }
  }
`