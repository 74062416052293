//External
import styled from "styled-components";

//Functions & Vars


//Components

export default function Icon(props) {

  const {
    icon,
    size,
    color,
    title
  } = props;

  return (
    <StyledIcon icon={icon} size={size} color={color} title={title} />
  );

}

const StyledIcon = styled.i`
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  mask: url(/icons/${props => props.icon}.svg) no-repeat;
  mask-size: ${props => props.size};
  background-color: ${props => props.color};
`