import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import {useState} from "react";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import Group from "../../../components/ui/Group";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import TinyMCE from "../../../components/form/controlled/TinyMCE";
import ControlledNumericInput from "../../../components/form/controlled/NumericInput";
import ControlledCheckbox from "../../../components/form/controlled/Checkbox";
import ControlledSelect from "../../../components/form/controlled/Select";
import ControlledDateInput from "../../../components/form/controlled/DateInput";

export default function ProjectDetails() {

  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line
  const [message, setMessage] = useState(<></>)

  return (
    <SubPage>
      <ActionBar>
        <ActionButton icon={"save"} onClick={null} />
      </ActionBar>
      { message }
      <Grid columns={"1fr 2fr"} gap={defaultStyles.element.padding.default}>
        <Grid columns={"1fr"} gap={defaultStyles.element.padding.default} maxContent>
          <Group name={"Grunddaten"} icon={"home"} maxContent>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Kundenname"} />
              <ControlledTextInput name={"Projektname"} />
              <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.small}>
                <ControlledNumericInput name={"Einwohnerzahl"} min={0} />
                <ControlledNumericInput name={"Mitarbeiterzahl"} min={0} />
              </Grid>
              <ControlledTextInput name={"Projektteilnehmer (Kunde)"} />
            </Grid>
          </Group>
          <Group name={"Musterlayout"} maxContent>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledCheckbox>Auf Musterbasis?</ControlledCheckbox>
              <ControlledSelect name={"Musterlayout"}>
                <option value={10100}>Kitaportal</option>
                <option value={10200}>Mieterportal</option>
                <option value={10300}>Cheaterportal</option>
                <option>004 - Friesoythe</option>
                <option>005 - NochnTestLayout</option>
              </ControlledSelect>
            </Grid>
          </Group>
          <Group name={"Farben"}>
            <Grid columns={"1fr 1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Primärfarbe"} />
              <ControlledTextInput name={"Sekundärfarbe"} />
            </Grid>
          </Group>
          <Group name={"Zeitplan"}>
            <Grid columns={"3fr 1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledDateInput name={"Fertigstellung Layout Desktop"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
              <ControlledDateInput name={"Fertigstellung Layout Layout"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
              <ControlledDateInput name={"Fertigstellung Layout Smartphone"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
              <ControlledDateInput name={"Fertigstellung Programmierung Desktop"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
              <ControlledDateInput name={"Fertigstellung Programmierung Tablet"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
              <ControlledDateInput name={"Fertigstellung Programmierung Smartphone"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
              <ControlledDateInput name={"Freischaltung"} />
              <ControlledCheckbox>Erledigt</ControlledCheckbox>
            </Grid>
          </Group>
        </Grid>
        <Group name={"Layout"} maxContent>
          <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
            <TinyMCE
              value={projectTemplate}
              onValueChange={newValue => console.log(newValue)}
            />
          </Grid>
        </Group>
      </Grid>
    </SubPage>
  )
}

const projectTemplate = `
  <h3 style="text-decoration: underline;">Header</h3>
  <p></p>
  <h3 style="text-decoration: underline;">Footer</h3>
  <p></p>
  <h3 style="text-decoration: underline;">Startseite</h3>
  <h4>Banner</h4>
  <p></p>
  <h4>Stickies</h4>
  <p></p>
  <h4>Schnellziele</h4>
  <p></p>
  <h3 style="text-decoration: underline;">Folgeseite</h3>
  <h4>Banner</h4>
  <p></p>
  <h4>Stickies</h4>
  <p></p>
  <h4>Schnellziele</h4>
  <p></p>
`;