export default function updateState(currentState, setterFunction, objKey, value, objectSectionMode = false) {
  if(objectSectionMode === false) {
    if(Array.isArray(objKey)) {
      let currentStateCopy = currentState
      for(const element in objKey) {
        currentStateCopy = {
          ...currentStateCopy,
          [objKey[element][0]]: objKey[element][1]
        }
      }
      setterFunction(currentStateCopy)
    }
    else {
      setterFunction({
        ...currentState,
        [objKey]: value
      })
    }
  }
  else {
    let currentStateCopy = currentState
    let newSectionObject = null;
    switch (objKey.length) {
      case 1: {
        newSectionObject = value
        break;
      }
      case 2: {
        newSectionObject = {
          ...currentStateCopy[objKey[0]],
          [objKey[1]]: value
        }
        break;
      }
      case 3: {
        newSectionObject = {
          ...currentStateCopy[objKey[0]],
          [objKey[1]]: {
            ...currentStateCopy[objKey[0]][objKey[1]],
            [objKey[2]]: value
          }
        }
        break;
      }
      case 4: {
        newSectionObject = {
          ...currentStateCopy[objKey[0]],
          [objKey[1]]: {
            ...currentStateCopy[objKey[0]][objKey[1]],
            [objKey[2]]: {
              ...currentStateCopy[objKey[0]][objKey[1]][objKey[2]],
              [objKey[3]]: value
            }
          }
        }
        break;
      }
      case 5: {
        newSectionObject = {
          ...currentStateCopy[objKey[0]],
          [objKey[1]]: {
            ...currentStateCopy[objKey[0]][objKey[1]],
            [objKey[2]]: {
              ...currentStateCopy[objKey[0]][objKey[1]][objKey[2]],
              [objKey[3]]: {
                ...currentStateCopy[objKey[0]][objKey[1]][objKey[2]][objKey[3]],
                [objKey[4]]: value
              }
            }
          }
        }
        break;
      }
      default: new Error("updateState - Falsche Ebene")
    }

    currentStateCopy = {
      ...currentStateCopy,
      [objKey[0]]: newSectionObject
    }
    setterFunction(currentStateCopy)
  }
}