import SubPage from "../../../components/ui/SubPage";
import ActionBar from "../../../components/ui/action_bar/ActionBar";
import ActionButton from "../../../components/ui/action_bar/ActionButton";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import styled, {css} from "styled-components";
import Group from "../../../components/ui/Group";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import {useState} from "react";
import {convertToDateStringFromDateObject} from "../../../lib/convertDate";
import ControlledCheckbox from "../../../components/form/controlled/Checkbox";
import updateState from "../../../lib/updateState";
import ControlledSelect from "../../../components/form/controlled/Select";
import Legend from "../../../components/ui/action_bar/Legend";

export default function NOLISPdfDashboard() {

  const navigate = useNavigate()
  const {
    documents,
    users,
    categories
  } = useLoaderData()



  const [searchTerm, setSearchTerm] = useState("")
  const [searchSettings, setSearchSettings] = useState({
    filterArchive: false,
    author: 1,
    category: 1
  })

  let filteredDocuments = documents.filter(document => document.name.toUpperCase().includes(searchTerm.toUpperCase()));
  if(searchSettings.author !== null && searchSettings.author !== 1) filteredDocuments = filteredDocuments.filter(document => {
    if(!document.author) return false;
    else if(document.author._id !== searchSettings.author) return false
    else return true
  })
  if(searchSettings.category !== null && searchSettings.category !== 1) filteredDocuments = filteredDocuments.filter(document => {
    if(!document.category) return false;
    else if(document.category._id !== searchSettings.category) return false;
    else return true
  })
  if(!searchSettings.filterArchive) filteredDocuments = filteredDocuments.filter(document => document.archived === false)

  function routeToNewEntry() {
    navigate("neu")
  }

  const documentsOutput = filteredDocuments.map((document, index) => {
    return (
      <StyledDocumentTile key={index} discover={document.discover}>
        <Link to={document._id}>
          <span>{ document.name }</span>
          <div>
            <span>Autor: <br />{!document.author ? "Unbekannt" : document.author.prename}</span>
            <span>Datum: <br />{convertToDateStringFromDateObject(document.fileDate)}</span>
            <span><br />v: {document.fileVersion}</span>
            <span>Kategorie: { !document.category ? "Unbekannt" : document.category.name }</span>
          </div>
        </Link>
      </StyledDocumentTile>
    )
  })

  return (
    <SubPage>
      <ActionBar
        buttonss={<ActionButton icon={"add"} title={"Neueintrag"} onClick={routeToNewEntry} />}
      />

        <Legend legend={[
          {
            color: defaultStyles.color.theme.default,
            title: "Öffentliche Dokumente"
          },
          {
            color: defaultStyles.color.red.dark,
            title: "Private Dokumente"
          }
        ]} />
      <Grid columns={"1fr"} gap={defaultStyles.element.padding.default}>
        <Group name={"Dokumente"} icon={"files"}>
          <Grid columns={"1fr"} gap={defaultStyles.element.padding.default}>
            <Grid columns={"2fr 6fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Suche"} value={searchTerm} onValueChange={setSearchTerm} />
              <Grid columns={"1fr 1fr 1fr"} gap={defaultStyles.element.padding.small} revert>
                <ControlledSelect name={"Ersteller"} value={searchSettings.author} onValueChange={newValue => updateState(searchSettings, setSearchSettings, ["author"], newValue, true)}>
                  <option value={1}>Alle</option>
                  {
                    users.map((user, index) => {
                      return <option key={index} value={user.id}>{user.prename}</option>
                    })
                  }
                </ControlledSelect>
                <ControlledSelect name={"Kategorie"} value={searchSettings.category} onValueChange={newValue => updateState(searchSettings, setSearchSettings, ["category"], newValue, true)}>
                  <option value={1}>Alle</option>
                  {
                    categories.map((category, index) => {
                      return <option key={index} value={category._id}>{category.name}</option>
                    })
                  }
                </ControlledSelect>
                <ControlledCheckbox value={searchSettings.filterArchive} onValueChange={newValue => updateState(searchSettings, setSearchSettings, ["filterArchive"], newValue, true)}>
                  Archivierte Dokumente berücksichtigen
                </ControlledCheckbox>
              </Grid>
            </Grid>
            <Grid columns={"1fr 1fr 1fr 1fr 1fr"} gap={defaultStyles.element.padding.small} maxContent>
              {
                documentsOutput
              }
            </Grid>
          </Grid>
        </Group>
      </Grid>
    </SubPage>
  )
}

const StyledDocumentTile = styled.div`
  width: 100%;
  border-radius: ${defaultStyles.element.border.radius.small};
  ${props => {
    if(props.discover === "public") return css`
      background-color: ${defaultStyles.color.theme.default};
      border: ${defaultStyles.element.border.default};
      &:hover {
        background-color: ${defaultStyles.color.theme.hover};
      }
    `;
    else if(props.discover === "private") return css`
      background-color: ${defaultStyles.color.red.dark};
      border: 1px solid ${defaultStyles.color.red.dark};
      &:hover {
        background-color: ${defaultStyles.color.red.light};
      }
    `
  }};
  &:hover {
    box-shadow: ${defaultStyles.element.boxShadow};
  }
  > a {
    display: grid;
    grid-template-rows: 1fr max-content;
    width: 100%;
    height: 100%;
    padding: ${defaultStyles.element.padding.small};
    color: #fff;
    text-decoration: none;
    > span {
      font-weight: 600;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
      margin-top: .5rem;
      span:nth-of-type(4) {
        margin-top: .5rem;
        grid-column: 1/4;
      }
    }
  }
`