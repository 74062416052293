import styled from "styled-components";
import { Outlet } from "react-router-dom";
import defaultStyles from "../../styles/defaultStyles";

export default function PublicLayout() {
  return (
    <StyledPublicLayout>
      <header>
        <span>OAPM</span>
      </header>
      <main>
        <Outlet />
      </main>
    </StyledPublicLayout>
  )
}

const StyledPublicLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1fr;
  > header {
    display: flex;
    align-items: center;
    background-color: ${defaultStyles.color.theme.default};
    padding: 0 4rem;
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
  }
  > main {
    padding: 2rem 4rem;
    background-color: ${defaultStyles.color.grey.disabledLight};
  }
`;