import SubPage from "../../../components/ui/SubPage";
import Grid from "../../../components/helper/Grid";
import Group from "../../../components/ui/Group";
import TextArea from "../../../components/form/controlled/TextArea";
import ControlledTextInput from "../../../components/form/controlled/TextInput";
import ControlledSelect from "../../../components/form/controlled/Select";
import defaultStyles from "../../../styles/defaultStyles";
import Form from "../../../components/form/Form";
import SubmitButton from "../../../components/form/SubmitButton";
import templateBfeNds from "../../../templates/bfe_niedersachsen";
import {useState} from "react";
import throwPageMessage from "../../../lib/throwPageMessage";
import updateState from "../../../lib/updateState";

export default function BFGen() {

  const [message, setMessage] = useState(<></>);
  const [bfeData, setBfeData] = useState({
    name: "",
    address: "",
    zipAndCity: "",
    state: 0,
    contactPerson: "",
    telephone: "",
    fax: "",
    mail: "",
    domain: "",
    output: ""
  })

  function onGenerateStatement() {
    const errors = [];
    if(!bfeData.name.length) errors.push("Es wurde keine Kommune angegeben.")
    if(!bfeData.address.length) errors.push("Es wurde keine Adresse angegeben.")
    if(!bfeData.zipAndCity.length) errors.push("Es wurde keine PLZ / kein Ort angegeben.")
    if(!bfeData.state) errors.push("Es wurde kein Bundesland gewählt.")
    if(!bfeData.contactPerson.length) errors.push("Es wurde kein Ansprechpartner angegeben.")
    if(!bfeData.telephone.length) errors.push("Es wurde keine Telefonnummer anagegeben.")
    if(!bfeData.fax.length) errors.push("Es wurde keine Faxnummer angegeben.")
    if(!bfeData.mail.length) errors.push("Es wurde keine Mailadresse angegeben.")
    if(!bfeData.domain.length) errors.push("Es wurde keine Domain angegeben.")
    if(errors.length) return throwPageMessage(setMessage, "error", errors, true)
    let bfeOutput = templateBfeNds;
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_NAME_%", bfeData.name)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_ADDRESS_%", bfeData.address)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_PLZ_ORT_%", bfeData.zipAndCity)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_AP_%", bfeData.contactPerson)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_TEL_%", bfeData.telephone)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_FAX_%", bfeData.fax)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_MAIL_%", bfeData.mail)
    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_URL_%", bfeData.domain)

    const date = new Date();
    const day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
    const month = date.getMonth()+1 < 10 ? "0"+date.getMonth()+1 : date.getMonth()+1;
    const year = date.getFullYear();
    const dateString = `${day}.${month}.${year}`

    bfeOutput = bfeOutput.replaceAll("%_KOMMUNE_GENDATE_%", dateString)
    updateState(bfeData, setBfeData, ["output"], bfeOutput, true)
    return throwPageMessage(setMessage, "success", "Die Erklärung konnte erfolgreich generiert werden.")
  }

  return (
    <SubPage>
      { message }
      <Grid columns={"1fr 3fr"} gap={defaultStyles.element.padding.default}>
        <Group name={"Einstellungen"}>
          <Form onSubmit={onGenerateStatement}>
            <Grid columns={"1fr"} gap={defaultStyles.element.padding.small}>
              <ControlledTextInput name={"Name der Kommune"} value={bfeData.name} onValueChange={newValue => updateState(bfeData, setBfeData, ["name"], newValue, true)} />
              <ControlledTextInput name={"Straße und Hausnummer"} value={bfeData.address} onValueChange={newValue => updateState(bfeData, setBfeData, ["address"], newValue, true)} />
              <ControlledTextInput name={"PLZ und Ort"} value={bfeData.zipAndCity} onValueChange={newValue => updateState(bfeData, setBfeData, ["zipAndCity"], newValue, true)} />
              <ControlledSelect name={"Bundesland"} value={bfeData.state} onValueChange={newValue => updateState(bfeData, setBfeData, ["state"], newValue, true)}>
                <option value={1}>Niedersachsen</option>
              </ControlledSelect>
              <ControlledTextInput name={"Ansprechpartner"} value={bfeData.contactPerson} onValueChange={newValue => updateState(bfeData, setBfeData, ["contactPerson"], newValue, true)} />
              <ControlledTextInput name={"Telefonnummer"} value={bfeData.telephone} onValueChange={newValue => updateState(bfeData, setBfeData, ["telephone"], newValue, true)} />
              <ControlledTextInput name={"Faxnummer"} value={bfeData.fax} onValueChange={newValue => updateState(bfeData, setBfeData, ["fax"], newValue, true)} />
              <ControlledTextInput name={"E-Mail-Adresse"} value={bfeData.mail} onValueChange={newValue => updateState(bfeData, setBfeData, ["mail"], newValue, true)} />
              <ControlledTextInput name={"Domain"} value={bfeData.domain} onValueChange={newValue => updateState(bfeData, setBfeData, ["domain"], newValue, true)} />
              <SubmitButton>Erklärung erstellen</SubmitButton>
            </Grid>
          </Form>
        </Group>
        <Group name={"Quellcode Barrierefreiheitserklärung"}>
          <TextArea maxHeight name={"Quellcode"} value={bfeData.output} onValueChange={() => null} />
        </Group>
      </Grid>
    </SubPage>
  )
}