//External
import {
  Outlet, useRouteLoaderData
} from "react-router-dom";

//Functions & Vars


//Components
import PageNavBar from "../../../components/ui/page_navigation/PageNavBar";
import NavTile from "../../../components/ui/page_navigation/NavTile";

export default function ToolsLayout() {

  const userData = useRouteLoaderData("root")

  return (
    <>
      <PageNavBar name={"Tools"}>
        <NavTile to={"dokumente"}>Dokumentenmanagement</NavTile>
        <NavTile to={"mail"}>Mail</NavTile>
        <NavTile to={"dns-resolver"}>DNS-Resolver</NavTile>
        <NavTile to={"bfgen"}>BFGen</NavTile>
      </PageNavBar>
      <Outlet />
    </>
  )
}