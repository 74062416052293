//External
import styled from "styled-components";

//Functions & Vars
import defaultStyles from "../../../styles/defaultStyles";

//Components


export default function ActionBar(props) {

  const {
    children,
    buttonss,
    extra
  } = props;

  console.log(props)

  let buttons, additionalContent;

  if(!Array.isArray(children)) {
    buttons = children
  }
  else {
    buttons = children.filter(item => item.type.name === "ActionButton").map(item => {
      return item;
    })

    additionalContent = children.filter(item => item.type.name !== "ActionButton").map(item => {
      return item
    })
  }

  return (
    <StyledActionBar>
      <div className={"buttons"}>
        {buttonss}
        {buttons}
      </div>
      <div className={"extra"}>
        { additionalContent }
      </div>
    </StyledActionBar>
  );
}

const StyledActionBar = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${defaultStyles.element.padding.default};
  > div.buttons {
    display: flex;
    gap: 1rem;
  }
  > span {
    display: inline-block;
    height: 2.5rem;
    align-self: center;
    border-left: 0.1rem solid ${defaultStyles.color.grey.light};
  }
`