import SubPage from "../../../components/ui/SubPage";
import Grid from "../../../components/helper/Grid";
import defaultStyles from "../../../styles/defaultStyles";
import styled from "styled-components";
import {Link} from "react-router-dom";

export default function MyProjects() {
  return (
    <SubPage>
      <Grid columns={"1fr 1fr 1fr 1fr"} gap={defaultStyles.element.padding.default}>
        <StyledProjectTile>
          <Link to={"/projekte/IDIDIDIDIDID"}>
            <span><b>Kunde:</b> Rolandstadt Perleberg</span>
            <span><b>Projekt:</b> Ersterstellung KP 2023</span>
          </Link>
        </StyledProjectTile>
      </Grid>
    </SubPage>
  )
}

const StyledProjectTile = styled.div`
  width: 100%;
  border: ${defaultStyles.element.border.default};
  border-radius: ${defaultStyles.element.border.radius.small};
  background-color: #fff;
  > a {
    display: grid;
    grid-template-columns: 1fr;
    padding: ${defaultStyles.element.padding.small};
    text-decoration: none;
    color: #000;
  }
  &:hover {
    box-shadow: ${defaultStyles.element.boxShadow};
  }
`