import styled from "styled-components";
import defaultStyles from "../../styles/defaultStyles";

export default function SubmitButton(props)  {
  const {
    style,
    children,
    onClick
  } = props;

  function innerOnClickHandler(e) {
    e.preventDefault();
    props.onClick();
  }

  if(!onClick) {
    return (
      <StyledSubmitButton style={style} type={"submit"}>{ children }</StyledSubmitButton>
    );
  }
  else {
    return (
      <StyledSubmitButton onClick={innerOnClickHandler} style={style} type={"submit"}>{ children }</StyledSubmitButton>
    );
  }
}

const StyledSubmitButton = styled.button `
  height: 2.25rem;
  color: #fff;
  background-color: ${defaultStyles.color.theme.default};
  border: 0;
  border-radius: ${defaultStyles.element.border.radius.small};
  cursor: pointer;
  &:hover {
    background-color: ${defaultStyles.color.theme.hover};
  }
`