//External
import styled, {css} from "styled-components";

//Functions & Vars
import defaultStyles from "../../styles/defaultStyles";

//Components


export default function List(props) {

  const {
    noGap,
    children
  } = props;

  let outputJSX;

  if(!children || !children.length)
    outputJSX = <span>Keine Elemente vorhanden</span>
  else
    outputJSX = children

  return (
    <StyledList noGap={noGap}>
      { outputJSX }
    </StyledList>
  );
}

const StyledList = styled.ul `
  display: grid;
  ${props => {
    if(props.noGap) return css `
      gap: 0;
    `
    else return css `
      gap: ${defaultStyles.element.padding.small};
    `
  }}
  width: 100%;
  margin: 0;
  padding: ${defaultStyles.element.padding.small};
  li {
    list-style-type: square;
    list-style-position: inside;
  }
`